import { useCallback, useMemo, useEffect } from 'react';
import compact from 'lodash/compact';
import first from 'lodash/first';
import find from 'lodash/find';
import merge from 'lodash/merge';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';

import { useProductBrands } from '../../../../../hooks/useProductBrands';
import { useProductBrandsSelected } from '../../../../../hooks/useProductBrandsSelected';

import { ProductCache } from '../../../../../ProductCache';

import {
  CategoryID,
  FetchCategoriesScopes
} from '../../../../../../categories/categoriesTypes';

import {
  FetchProductsFilters,
  FetchProductCategoriesFilters,
  ProductClientID,
  ChangeProductsFiltersFunc
} from '../../../../../productsTypes';
import { ProductBrandsDataItem } from '../../ProductsBrandsFilter.types';

import { getProductClientIdsCacheKeyPart } from '../../../../../utils/getProductClientIdsCacheKeyPart';

const defaultInitialFilters = {
  scope: [FetchCategoriesScopes.FACILITY_MANUFACTURERS]
};

interface useProductsBrandsFilterProps {
  clientIds?: ProductClientID[];
  selectedIds: CategoryID[];
  name: string;
  productsFilters: FetchProductsFilters;
  changeProductsFilters: ChangeProductsFiltersFunc;
}

const defaultFilterKeys: Array<keyof FetchProductCategoriesFilters> = [
  'scope',
  'productClientIds',
  'productCategoryIds',
  'productParentCategoryIds'
];

function useProductsBrandsFilter({
  clientIds,
  selectedIds = [],
  name,
  productsFilters,
  changeProductsFilters
}: useProductsBrandsFilterProps) {
  const categoryId = first(productsFilters?.productCategoryId?.in);
  const parentCategoryId = first(productsFilters?.productParentCategoryIds);

  const cacheKey = isEmpty(clientIds)
    ? ProductCache.brandsFilterCacheKey(categoryId || parentCategoryId)
    : ProductCache.brandsLibraryFilterCacheKey(
        getProductClientIdsCacheKeyPart({ clientIds }),
        categoryId || parentCategoryId
      );

  const defaultFilters: FetchProductCategoriesFilters = merge(
    {},
    defaultInitialFilters,
    isEmpty(clientIds) ? null : { productClientIds: clientIds },
    categoryId ? { productCategoryIds: [categoryId] } : null,
    parentCategoryId && !categoryId
      ? {
          productParentCategoryIds: [parentCategoryId]
        }
      : null
  );

  const {
    productBrands,
    productBrandsErrorMessage,
    productBrandsFetched,
    productBrandsIsPlaceholderData,
    productBrandsFetchingNextPage,
    productBrandsFilters,
    hasNextProductBrandsPage,
    productBrandsFilterSearchValue,
    loadMoreProductBrands,
    filterProductBrands,
    changeProductBrandsFilters
  } = useProductBrands({
    cacheKey,
    initialFilters: defaultFilters
  });

  useEffect(() => {
    if (
      !isEqual(defaultFilters, pick(productBrandsFilters, defaultFilterKeys))
    ) {
      filterProductBrands({
        ...defaultFilters,
        ...pickBy(
          productBrandsFilters,
          (value, key) => !includes(defaultFilterKeys, key)
        )
      });
    }
  }, [productBrandsFilters, defaultFilters, filterProductBrands]);

  const { productBrandsSelected, productsBrandsSelectedErrorMessage } =
    useProductBrandsSelected({
      selectedIds,
      cacheKey: ProductCache.brandsSelectedFilterCacheKey(),
      initialFilters: {
        scope: [FetchCategoriesScopes.FACILITY_MANUFACTURERS],
        id: { in: [] }
      },
      keepPreviousData: true
    });

  const handleProductBrandsFilterSearch = useCallback(
    (input: string) => {
      changeProductBrandsFilters(
        { name: { ilike: input } },
        input ? [] : ['name']
      );
    },
    [changeProductBrandsFilters]
  );

  const productBrandsFilterSelectedData = useMemo(() => {
    const selectedFetchedData = productBrandsSelected.map((category) => ({
      id: category.id as string,
      label: category.name
    }));

    const selectedData = compact(
      selectedIds.map((id) => find(selectedFetchedData, ['id', id]))
    );
    return selectedData;
  }, [productBrandsSelected, selectedIds]);

  const productsBrandsFilterData = useMemo<ProductBrandsDataItem[]>(() => {
    return productBrands.map((category) => ({
      id: category.id as string,
      label: category.name
    }));
  }, [productBrands]);

  const handleChange = useCallback<
    (
      changedFilters: { [name: string]: string[] | undefined },
      removeFilters: string[]
    ) => void
  >(
    (changedFilters, removeFilters) => {
      changeProductsFilters(
        { [name]: { in: changedFilters?.[name] || [] } },
        removeFilters
      );
    },
    [changeProductsFilters, name]
  );

  return {
    productBrandsFilterData: productBrands,
    productBrandsFilterSelectedData,
    productBrandsFilter: productBrands,
    productsBrandsFilterData,
    productBrandsFilterErrorMessage: productBrandsErrorMessage,
    productsBrandsFilterSelectedErrorMessage:
      productsBrandsSelectedErrorMessage,
    productBrandsFilterFetched: productBrandsFetched,
    productBrandsFilterIsPlaceholderData: productBrandsIsPlaceholderData,
    productBrandsFilterFetchingNextPage: productBrandsFetchingNextPage,
    hasNextProductBrandsFilterPage: hasNextProductBrandsPage,
    productBrandsFilterSearchValue,
    handleProductBrandsFilterSearch,
    loadMoreProductBrandsFilter: loadMoreProductBrands,
    handleChangeProductsBrandsFilter: handleChange
  };
}

export default useProductsBrandsFilter;
