import React, { Fragment } from 'react';

import {
  FetchProductsCacheKeys,
  OnSelectedProductsSidebarCloseAction,
  OnSelectedProductsSidebarOpenAction,
  UpdateProductCacheAction
} from '../../../../../productsTypes';

import {
  UpdateProductsSetCacheAction,
  FetchProductsSetsCacheKeys
} from '../../../../../../productsSets/productsSetsTypes';

import { ProductsListItemLightboxDownloadButton } from '../ProductsListItemLightboxDownloadButton';
import { ProductsListItemLightboxFavoriteButton } from '../ProductsListItemLightboxFavoriteButton';
import { ProductsListItemLightboxSelectButton } from '../ProductsListItemLightboxSelectButton';

import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import {
  ProductsListItemLightboxBottomButtonsProduct,
  ProductsListItemLightboxBottomButtonsProductsSet
} from './ProductsListItemLightboxBottomButtons.types';

import { ProductsPermissions } from '../../../../../productsConstants';

interface ProductsListItemLightboxBottomButtonsProps {
  product: ProductsListItemLightboxBottomButtonsProduct;
  productsSet: ProductsListItemLightboxBottomButtonsProductsSet | null;
  productsCacheKeys?: FetchProductsCacheKeys;
  productsSetCacheKeys?: FetchProductsSetsCacheKeys;
  onSelectedProductsSidebarOpen: OnSelectedProductsSidebarOpenAction;
  onSelectedProductsSidebarClose: OnSelectedProductsSidebarCloseAction;
  updateProductsSetCache: UpdateProductsSetCacheAction<ProductsListItemLightboxBottomButtonsProductsSet>;
  updateProductCache: UpdateProductCacheAction<ProductsListItemLightboxBottomButtonsProduct>;
}

function ProductsListItemLightboxBottomButtons({
  product,
  productsSet,
  productsCacheKeys,
  productsSetCacheKeys,
  onSelectedProductsSidebarOpen,
  onSelectedProductsSidebarClose,
  updateProductsSetCache,
  updateProductCache
}: ProductsListItemLightboxBottomButtonsProps) {
  return (
    <Fragment>
      <ProductsListItemLightboxSelectButton
        product={product}
        productsSet={productsSet}
        productsSetCacheKeys={productsSetCacheKeys}
        onSelectedProductsSidebarOpen={onSelectedProductsSidebarOpen}
        onSelectedProductsSidebarClose={onSelectedProductsSidebarClose}
        updateProductsSetCache={updateProductsSetCache}
      />
      <ProductsListItemLightboxFavoriteButton
        product={product}
        productsCacheKeys={productsCacheKeys}
        productsSetCacheKeys={productsSetCacheKeys}
        updateProductCache={updateProductCache}
      />
      <CheckPermissions
        action={ProductsPermissions.READ_PRODUCT_DOWNLOAD_BUTTON}
      >
        <ProductsListItemLightboxDownloadButton product={product} />
      </CheckPermissions>
    </Fragment>
  );
}

export default ProductsListItemLightboxBottomButtons;
