import { useCallback } from 'react';

import { FetchProductsSort, FetchProductsSortTypes } from '../../productsTypes';

interface ProductsSortDropdownOptions {
  sortProducts: (nextSort: FetchProductsSort) => void;
}

function useProductsSortDropdown({
  sortProducts
}: ProductsSortDropdownOptions) {
  const handleSortByMostLiked = useCallback<() => void>(() => {
    sortProducts([FetchProductsSortTypes.USERS_CHOSEN_PRODUCTS_COUNT_DESC]);
  }, [sortProducts]);

  const handleSortByMostUsed = useCallback<() => void>(() => {
    sortProducts([FetchProductsSortTypes.SELECTED_PRODUCTS_COUNT_DESC]);
  }, [sortProducts]);

  const handleSortByNewest = useCallback<() => void>(() => {
    sortProducts([FetchProductsSortTypes.CREATED_AT_DESC]);
  }, [sortProducts]);

  return {
    handleSortByMostLiked,
    handleSortByMostUsed,
    handleSortByNewest
  };
}

export default useProductsSortDropdown;
