import React from 'react';
import cl from 'classnames';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import {
  FetchProductsCacheKeys,
  UpdateProductCacheAction
} from '../../../../../productsTypes';

import { FetchProductsSetsCacheKeys } from '../../../../../../productsSets/productsSetsTypes';

import { PureTooltipIconButtonHelper } from '../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import useProductsListItemFavoriteButton from './useProductsListItemFavoriteButton';

import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';

import { ProductsListItemFavoriteButtonProduct } from './ProductsListItemFavoriteButton.types';

import { words } from '../../../../../../../locales/keys';

interface ProductsListItemFavoriteButtonProps {
  product: ProductsListItemFavoriteButtonProduct;
  productsCacheKeys?: FetchProductsCacheKeys;
  productsSetCacheKeys?: FetchProductsSetsCacheKeys;
  updateProductCache: UpdateProductCacheAction<ProductsListItemFavoriteButtonProduct>;
}

function ProductsListItemFavoriteButton({
  product,
  productsCacheKeys,
  productsSetCacheKeys,
  updateProductCache
}: ProductsListItemFavoriteButtonProps) {
  const { handleToggleFavorite } = useProductsListItemFavoriteButton({
    product,
    productsCacheKeys,
    productsSetCacheKeys,
    updateProductCache
  });

  return (
    <PureTooltipIconButtonHelper
      className={cl(
        'focus:ring-offset-0 items-center rounded-full',
        product.favorite
          ? 'text-pink-600 hover:text-pink-500 flex p-0.5'
          : 'text-gray-400 dark:text-gray-500 hover:text-pink-600 dark:hover:text-pink-600 flex p-1'
      )}
      icon={product.favorite ? IconsEnum.HEART_SOLID : IconsEnum.HEART_OUTLINE}
      tooltipI18nText={
        product.favorite ? words.removeFromFavorites : words.addToFavorites
      }
      tooltipPlacement={TooltipPlacement.TOP}
      onClick={handleToggleFavorite}
    />
  );
}

export default ProductsListItemFavoriteButton;
