import React from 'react';
import cl from 'classnames';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import {
  FetchProductsCacheKeys,
  UpdateProductCacheAction
} from '../../../../../productsTypes';

import { FetchProductsSetsCacheKeys } from '../../../../../../productsSets/productsSetsTypes';

import { PureTooltipIconButtonHelper } from '../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import useProductsListItemLightboxFavoriteButton from './useProductsListItemLightboxFavoriteButton';

import { ProductsListItemLightboxFavoriteButtonProduct } from './ProductsListItemLightboxFavoriteButton.types';

import { words } from '../../../../../../../locales/keys';

interface ProductsListItemLightboxFavoriteButtonProps {
  product: ProductsListItemLightboxFavoriteButtonProduct;
  productsCacheKeys?: FetchProductsCacheKeys;
  productsSetCacheKeys?: FetchProductsSetsCacheKeys;
  updateProductCache: UpdateProductCacheAction<ProductsListItemLightboxFavoriteButtonProduct>;
}

function ProductsListItemLightboxFavoriteButton({
  product,
  productsCacheKeys,
  productsSetCacheKeys,
  updateProductCache
}: ProductsListItemLightboxFavoriteButtonProps) {
  const { handleToggleFavorite } = useProductsListItemLightboxFavoriteButton({
    product,
    productsCacheKeys,
    productsSetCacheKeys,
    updateProductCache
  });

  return (
    <PureTooltipIconButtonHelper
      className={cl(
        'py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-white focus:ring-offset-0 hover:bg-white hover:bg-opacity-10 text-gray-300 hover:text-white',
        product.favorite ? 'text-pink-600' : null
      )}
      icon={product.favorite ? IconsEnum.HEART_SOLID : IconsEnum.HEART_OUTLINE}
      iconClassName={cl('h-6 w-6', product.favorite ? 'p-0.5' : null)}
      tooltipSingleton
      tooltipI18nText={
        product.favorite ? words.removeFromFavorites : words.addToFavorites
      }
      onClick={handleToggleFavorite}
    />
  );
}

export default ProductsListItemLightboxFavoriteButton;
