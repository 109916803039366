import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import { ProductsListItemSkuProduct } from './ProductsListItemSku.types';

import { Translate } from '../../../../../../../helpers/Translate';
import { Tooltip } from '../../../../../../../helpers/tooltips/Tooltip';
import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';

import { words } from '../../../../../../../locales/keys';

interface ProductsListItemSkuProps {
  product: ProductsListItemSkuProduct;
}

function ProductsListItemSku({ product }: ProductsListItemSkuProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLSpanElement | null>(null);

  if (isEmpty(product.sku)) {
    return null;
  }

  return product.sku ? (
    <div
      className="text-gray-600 dark:text-gray-500 text-2xs truncate"
      ref={setReferenceTooltipElement}
    >
      <span>
        <Translate id={words.sku} />
        {': '} {product.sku}
      </span>

      <Tooltip
        withArrow
        placement={TooltipPlacement.TOP}
        referenceElement={referenceTooltipElement}
        tooltipText={product.sku}
        interactive
      />
    </div>
  ) : null;
}

export default ProductsListItemSku;
