import React, { useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import without from 'lodash/without';

import {
  FetchProductsFilters,
  ChangeProductsFiltersFunc
} from '../../../../../productsTypes';

import { PureTooltipIconButtonHelper } from '../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';

import { ProductsListItemBrandNameFilterButtonProduct } from './ProductsListItemBrandNameFilterButton.types';

interface ProductsListItemBrandNameFilterButtonProps {
  product: ProductsListItemBrandNameFilterButtonProduct;
  productsFilters: FetchProductsFilters;
  changeProductsFilters: ChangeProductsFiltersFunc;
}

function ProductsListItemBrandNameFilterButton({
  product,
  productsFilters,
  changeProductsFilters
}: ProductsListItemBrandNameFilterButtonProps) {
  const brandId = product.brand?.id;

  const handleClick = useCallback<() => void>(() => {
    const currentBrandFilterIds = productsFilters?.brandId?.in || [];

    if (includes(currentBrandFilterIds, brandId)) {
      const currentBrandFilterIdsWithoutProductBrand = without(
        currentBrandFilterIds,
        brandId
      );
      changeProductsFilters(
        {
          brandId: { in: currentBrandFilterIdsWithoutProductBrand }
        },
        isEmpty(currentBrandFilterIdsWithoutProductBrand) ? ['brandId'] : null
      );
    } else {
      changeProductsFilters({
        brandId: { in: [...currentBrandFilterIds, brandId] }
      });
    }
  }, [brandId, productsFilters, changeProductsFilters]);

  if (!product.brand?.localizedName) {
    return null;
  }
  return (
    <PureTooltipIconButtonHelper
      className="focus:ring-offset-0 focus:ring-0 hover:opacity-80"
      text={product.brand.localizedName}
      tooltipText={product.brand.localizedName}
      tooltipPlacement={TooltipPlacement.BOTTOM}
      icon={null}
      onClick={handleClick}
    />
  );
}

export default ProductsListItemBrandNameFilterButton;
