import React, { memo, useCallback, useMemo } from 'react';
import some from 'lodash/some';

import { OnLightboxOpenAction } from '../../../../../helpers/LightboxWrapper';

import {
  UpdateProductsSetCacheAction,
  FetchProductsSetsCacheKeys
} from '../../../../productsSets/productsSetsTypes';

import {
  FetchProductsCacheKeys,
  OnSelectedProductsSidebarCloseAction,
  OnSelectedProductsSidebarOpenAction,
  UpdateProductCacheAction,
  ProductNanoID,
  FetchProductsFilters,
  ChangeProductsFiltersFunc
} from '../../../productsTypes';
import {
  ProductsListItemProduct,
  ProductsListItemProductsSet,
  ProductsListItemOnProductsSelect
} from './ProductsListItem.types';
import { IconsEnum } from '../../../../../assets/icons/types';

import { ProductsListItemBrandNameFilterButton } from './components/ProductsListItemBrandNameFilterButton';
import { ProductsListItemCopyLinkButton } from './components/ProductsListItemCopyLinkButton';
import { DownloadProductModalButton } from '../../buttons/DownloadProductModalButton';
import { ProductsListItemFavoriteButton } from './components/ProductsListItemFavoriteButton';
import { ProductsListItemEditLink } from './components/ProductsListItemEditLink';
import { ProductsListItemNda } from './components/ProductsListItemNda';
import { ProductsListItemOpenLightboxButton } from './components/ProductsListItemOpenLightboxButton';
import { ProductsListItemPreview } from './components/ProductsListItemPreview';
import { ProductSelectButton } from '../../buttons/ProductSelectButton';
import { ProductsListItemUpdateImageVersions } from './components/ProductsListItemUpdateImageVersions';
import { SubmitProductToProjectOrTaskModalButton } from './components/SubmitProductToProjectOrTaskModalButton';
import { ProductsListItemSku } from './components/ProductsListItemSku';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';
import { NextPureTooltipIconLinkHelper } from '../../../../../helpers/links/NextPureTooltipIconLinkHelper';
import { TooltipSingletonSourceWrapper } from '../../../../../helpers/tooltips/TooltipSingletonSourceWrapper';

import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { ProductsPermissions } from '../../../productsConstants';

import { words } from '../../../../../locales/keys';
import { ProductPath } from '../../../ProductPath';

interface ProductsListItemProps {
  product: ProductsListItemProduct;
  productsSet: ProductsListItemProductsSet | null;
  productsCacheKeys?: FetchProductsCacheKeys;
  productsSetCacheKeys?: FetchProductsSetsCacheKeys;
  productsFilters: FetchProductsFilters;
  changeProductsFilters: ChangeProductsFiltersFunc;
  onLightboxOpen: OnLightboxOpenAction;
  onSelectedProductsSidebarOpen: OnSelectedProductsSidebarOpenAction;
  onSelectedProductsSidebarClose: OnSelectedProductsSidebarCloseAction;
  updateProductsSetCache: UpdateProductsSetCacheAction<ProductsListItemProductsSet>;
  updateProductCache: UpdateProductCacheAction<ProductsListItemProduct>;
  onProductsSelect?: ProductsListItemOnProductsSelect;
  onProductEditButtonMouseEnter?: (productNanoId: ProductNanoID) => void;
}

function ProductsListItem({
  product,
  productsSet,
  productsCacheKeys,
  productsSetCacheKeys,
  productsFilters,
  changeProductsFilters,
  onLightboxOpen,
  onSelectedProductsSidebarOpen,
  onSelectedProductsSidebarClose,
  updateProductsSetCache,
  updateProductCache,
  onProductsSelect,
  onProductEditButtonMouseEnter
}: ProductsListItemProps) {
  const handleOpenLightbox = useCallback(() => {
    if (product.image) {
      onLightboxOpen(product.image);
    }
  }, [product, onLightboxOpen]);

  const isSelected = useMemo<boolean>(() => {
    if (!productsSet || !productsSet.selectedProducts) {
      return false;
    }
    return some(
      productsSet.selectedProducts,
      (selectedProduct) => selectedProduct?.product.uuid === product.uuid
    );
  }, [product, productsSet]);

  const handleEditButtonMouseEnter = useCallback<() => void>(() => {
    onProductEditButtonMouseEnter?.(product.nanoId);
  }, [onProductEditButtonMouseEnter, product.nanoId]);

  return (
    <div className="w-80 max-w-full mx-auto relative rounded-md group">
      <ProductsListItemPreview
        product={product}
        onLightboxOpen={handleOpenLightbox}
      />
      <div className="text-sm p-3">
        <div className="font-medium truncate">
          <NextPureTooltipIconLinkHelper
            href={ProductPath.show(product.nanoId)}
            text={product.name}
            tooltipI18nText={product.name}
            tooltipPlacement={TooltipPlacement.BOTTOM}
            icon={null}
          />
        </div>
        <div className="text-gray-600 dark:text-gray-500 truncate">
          <ProductsListItemNda product={product} />
          <ProductsListItemBrandNameFilterButton
            product={product}
            productsFilters={productsFilters}
            changeProductsFilters={changeProductsFilters}
          />
          <ProductsListItemSku product={product} />
        </div>
        <div className="mt-3 flex justify-between items-center relative z-5">
          <ProductSelectButton
            product={product}
            productsSet={productsSet}
            productsSetCacheKeys={productsSetCacheKeys}
            onSelectedProductsSidebarOpen={onSelectedProductsSidebarOpen}
            onSelectedProductsSidebarClose={onSelectedProductsSidebarClose}
            updateProductsSetCache={updateProductsSetCache}
            onSelect={onProductsSelect}
            selectedClassName="border font-medium hover:shadow inline-flex items-center px-2 py-1 relative rounded-md shadow-sm text-sm text-white bg-blue-500 hover:bg-blue-600 border-transparent space-x-1"
            unselectedClassName="border font-medium hover:shadow inline-flex items-center px-2 py-1 relative rounded-md shadow-sm text-sm border-gray-300 dark:border-gray-700 dark:hover:bg-gray-700 dark:text-gray-300 text-gray-700 hover:bg-gray-50"
          />
          <CheckPermissions
            action={ProductsPermissions.READ_PRODUCT_FAVORITE_BUTTON}
          >
            <ProductsListItemFavoriteButton
              product={product}
              productsCacheKeys={productsCacheKeys}
              productsSetCacheKeys={productsSetCacheKeys}
              updateProductCache={updateProductCache}
            />
          </CheckPermissions>
        </div>
      </div>
      {isSelected ? (
        <div className="absolute inset-0 rounded-md ring-4 ring-blue-500 bg-blue-500 opacity-10 pointer-events-none" />
      ) : null}
      {product.blocked ? (
        <div className="absolute inset-0 rounded-md bg-crossed bg-red-200 bg-opacity-10 pointer-events-none" />
      ) : null}
      <div className="absolute inset-0 pointer-events-none flex sm:opacity-0 group-hover:opacity-100 focus-within:opacity-100 items-start justify-end">
        <div className="flex flex-col space-y-1 pointer-events-auto p-2">
          <TooltipSingletonSourceWrapper
            placement={TooltipPlacement.LEFT}
            withArrow
          >
            <CheckPermissions
              action={ProductsPermissions.READ_PRODUCT_DOWNLOAD_BUTTON}
            >
              <DownloadProductModalButton
                productUuid={product.uuid}
                className="bg-white dark:bg-gray-900 dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1 rounded-md text-gray-700 text-sm pointer-events-auto shadow dark:glow"
                icon={IconsEnum.DOWNLOAD_SOLID}
                iconClassName="h-5 w-5"
                tooltipI18nText={words.download}
                tooltipSingleton
              />
            </CheckPermissions>

            <CheckPermissions
              action={
                ProductsPermissions.READ_PRODUCT_SUBMIT_TO_TASK_OR_PROJECT_BUTTON
              }
            >
              <SubmitProductToProjectOrTaskModalButton product={product} />
            </CheckPermissions>

            <ProductsListItemOpenLightboxButton
              product={product}
              onLightboxOpen={handleOpenLightbox}
            />
            <CheckPermissions
              action={
                ProductsPermissions.READ_PRODUCT_UPDATE_IMAGE_VERSION_BUTTON
              }
            >
              <ProductsListItemUpdateImageVersions product={product} />
            </CheckPermissions>
            <CheckPermissions
              action={ProductsPermissions.READ_PRODUCT_COPY_LINK_BUTTON}
            >
              <ProductsListItemCopyLinkButton product={product} />
            </CheckPermissions>
            <CheckPermissions
              action={ProductsPermissions.READ_PRODUCT_EDIT_BUTTON}
            >
              <ProductsListItemEditLink
                product={product}
                onMouseEnter={handleEditButtonMouseEnter}
              />
            </CheckPermissions>
          </TooltipSingletonSourceWrapper>
        </div>
      </div>
    </div>
  );
}

export default memo<ProductsListItemProps>(ProductsListItem);
