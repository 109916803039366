import React, { useCallback } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { PureTooltipIconButtonHelper } from '../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { copyToClipboard } from '../../../../../../../utils/copyToClipboard';

import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';

import { ProductsListItemCopyLinkButtonProduct } from './ProductsListItemCopyLinkButton.type';

import { words } from '../../../../../../../locales/keys';

import { ProductPath } from '../../../../../ProductPath';

interface ProductsListItemCopyLinkButtonProps {
  product: ProductsListItemCopyLinkButtonProduct;
}

function ProductsListItemCopyLinkButton({
  product
}: ProductsListItemCopyLinkButtonProps) {
  const handleCopyLinkToClipboard = useCallback<() => void>(
    () =>
      copyToClipboard(
        `${window.location.origin}${ProductPath.show(product.nanoId)}`
      ),
    [product.nanoId]
  );

  return (
    <PureTooltipIconButtonHelper
      className="bg-white dark:bg-gray-900 dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1 rounded-md text-gray-700 text-sm pointer-events-auto shadow dark:glow"
      icon={IconsEnum.LINK_SOLID}
      iconClassName="h-5 w-5 stroke-1.75"
      tooltipSingleton
      tooltipI18nText={words.copyLink}
      tooltipPlacement={TooltipPlacement.LEFT}
      onClick={handleCopyLinkToClipboard}
    />
  );
}

export default ProductsListItemCopyLinkButton;
