import React from 'react';
import map from 'lodash/map';

import { ProductsListItemPreviewProduct } from './ProductsListItemPreview.types';
import { IconsEnum } from '../../../../../../../assets/icons/types';

import { ButtonHelper } from '../../../../../../../helpers/buttons/ButtonHelper';
import { Icon } from '../../../../../../../helpers/Icon';
import {
  ImageHelper,
  ImageItemImageVersions
} from '../../../../../../../helpers/ImageHelper';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import { Files } from '../../../../../../../utils/Files';

import {
  ProductRenderTypeIcons,
  ProductsPermissions
} from '../../../../../productsConstants';

const srcSetVersions = {
  '1x': ImageItemImageVersions.MiniThumb320x320,
  '2x': ImageItemImageVersions.MiniThumb640x640,
  '3x': ImageItemImageVersions.MiniThumb960x960
};

interface ProductsListItemPreviewProps {
  product: ProductsListItemPreviewProduct;
  onLightboxOpen: () => void;
}

function ProductsListItemPreview({
  product,
  onLightboxOpen
}: ProductsListItemPreviewProps) {
  return (
    <div className="relative rounded-md overflow-hidden aspect-w-1 aspect-h-1">
      {product.image?.file && Files.isImage(product.image?.file) ? (
        <ButtonHelper className="absolute inset-0" onClick={onLightboxOpen}>
          <ImageHelper
            className="absolute inset-0 object-cover"
            src={product.image.file}
            version={ImageItemImageVersions.MiniThumb320x320}
            srcSetVersions={srcSetVersions}
            alt={product.name}
          />
          <CheckPermissions
            action={ProductsPermissions.READ_PRODUCT_RENDER_TYPES}
          >
            <div className="absolute inset-0 pointer-events-none flex items-end justify-end">
              <div className="flex gap-1 pointer-events-auto p-2">
                {map(product.renderTypes, (type) => (
                  <Icon
                    key={type.name}
                    icon={ProductRenderTypeIcons[type.name]}
                  />
                ))}
              </div>
            </div>
          </CheckPermissions>
        </ButtonHelper>
      ) : (
        <div className="flex items-center justify-center text-gray-600 absolute inset-0">
          <Icon icon={IconsEnum.PHOTOGRAPH_OUTLINE} className="h-12 w-12" />
        </div>
      )}
    </div>
  );
}

export default ProductsListItemPreview;
