import React, { useMemo } from 'react';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';

import {
  FetchProductsFilters,
  ProductClientID,
  ChangeProductsFiltersFunc
} from '../../../productsTypes';
import { FetchCategoriesScopes } from '../../../../categories/categoriesTypes';

import { useProductMaterials } from '../../../hooks/useProductMaterials';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { CheckboxGroupFilter } from '../../../../../helpers/filters/CheckboxGroupFilter';
import { Translate } from '../../../../../helpers/Translate';

import { getProductClientIdsCacheKeyPart } from '../../../utils/getProductClientIdsCacheKeyPart';

import { categoriesKeys } from '../../../../../locales/keys';

import { ProductsMaterialsFilterDataItem } from './ProductsMaterialsFilter.types';

import { ProductCache } from '../../../ProductCache';

interface ProductsMaterialsFilterProps {
  clientIds?: ProductClientID[];
  productsFilters: FetchProductsFilters;
  changeProductsFilters: ChangeProductsFiltersFunc;
}

const name = 'materialIds' as const;

const defaultInitialFilters = {
  scope: [FetchCategoriesScopes.FACILITY_MATERIALS]
};

function ProductsMaterialsFilter({
  clientIds,
  productsFilters,
  changeProductsFilters
}: ProductsMaterialsFilterProps) {
  const cacheKey = isEmpty(clientIds)
    ? ProductCache.materialsFilterCacheKey()
    : ProductCache.materialsLibraryFilterCacheKey(
        getProductClientIdsCacheKeyPart({ clientIds })
      );
  const initialFilters = isEmpty(clientIds)
    ? defaultInitialFilters
    : { ...defaultInitialFilters, productClientIds: clientIds };

  const {
    productMaterials,
    productMaterialsErrorMessage,
    productMaterialsFetched,
    productMaterialsIsPlaceholderData
  } = useProductMaterials({
    cacheKey,
    initialFilters
  });

  const ProductsMaterialsFilterItems = useMemo<
    ProductsMaterialsFilterDataItem[]
  >(
    () =>
      sortBy(productMaterials, 'localizedName').map((category) => ({
        id: category.id as string,
        label: category.localizedName
      })),
    [productMaterials]
  );

  return (
    <div className="p-4">
      <div className="flex mb-3">
        <h6 className="font-light uppercase">
          <Translate id={categoriesKeys.materials.singular} />
        </h6>
      </div>
      <AlertMessage message={productMaterialsErrorMessage} />
      <LoadingSkeleton
        loaded={productMaterialsIsPlaceholderData || productMaterialsFetched}
      >
        <CheckboxGroupFilter
          inputWrapperClassName="flex flex-col sm:flex-row sm:flex-wrap -mb-2"
          name={name}
          items={ProductsMaterialsFilterItems}
          value={productsFilters?.[name] as string[]}
          onChange={changeProductsFilters}
        />
      </LoadingSkeleton>
    </div>
  );
}

export default ProductsMaterialsFilter;
