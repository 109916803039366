import { useCallback } from 'react';

import {
  FetchProductsFilters,
  FetchProductsSort,
  FetchProductsLimit,
  FetchProductsGqlQuery,
  FetchProductsCacheKey,
  ProductNanoID
} from '../../productsTypes';
import { FetchItemCacheKey, FetchItemsGqlQuery, UUID } from '../../../../types';

import {
  useInfiniteIndexQuery,
  InfiniteIndexQueryBaseNodeType
} from '../../../common/hooks/base/reactQuery/useInfiniteIndexQuery';

import {
  INITIAL_PRODUCTS_FILTERS,
  INITIAL_PRODUCTS_LIMIT,
  INITIAL_PRODUCTS_SORT
} from '../../productsConstants';

interface ProductsWithPrefetchItemOptions {
  fetchItemCacheKey: FetchItemCacheKey;
  fetchItemQuery: FetchItemsGqlQuery;
}

interface ProductsWithoutPrefetchItemOptions {
  fetchItemCacheKey?: never;
  fetchItemQuery?: never;
}

interface ProductsDefaultOptions {
  cacheKey: FetchProductsCacheKey;
  query: FetchProductsGqlQuery;
  initialFilters?: FetchProductsFilters;
  initialSort?: FetchProductsSort;
  initialLimit?: FetchProductsLimit;
  options?: {
    cacheTime?: number;
    staleTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
  };
}

type ProductsOptions = ProductsDefaultOptions &
  (ProductsWithoutPrefetchItemOptions | ProductsWithPrefetchItemOptions);

const scope = 'products';

function useProducts<ProductItemType extends InfiniteIndexQueryBaseNodeType>({
  cacheKey,
  query,
  initialFilters = INITIAL_PRODUCTS_FILTERS,
  initialSort = INITIAL_PRODUCTS_SORT,
  initialLimit = INITIAL_PRODUCTS_LIMIT,
  fetchItemCacheKey,
  fetchItemQuery,
  options = {}
}: ProductsOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isFetchingNextPage,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    hasNextPage,
    updateItemCache,
    loadMoreItems,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    prefetchItem,
    limitItems
  } = useInfiniteIndexQuery<ProductItemType>({
    cacheKey,
    initialFilters,
    initialLimit,
    initialSort,
    fetchItemCacheKey,
    fetchItemQuery,
    options,
    query,
    scope
  });

  return {
    productsData: data,
    products: items,
    productsError: itemsError,
    productsTotalCount: itemsTotalCount,
    productsFetched: isFetched,
    productsLoading: isLoading,
    productsFetchingNextPage: isFetchingNextPage,
    productsIsPlaceholderData: isPlaceholderData,
    productsFilters: currentFilters,
    productsSort: currentSort as FetchProductsSort,
    productsPage: currentPage,
    productsLimit: currentLimit,
    hasNextProductsPage: hasNextPage,
    updateProductCache: updateItemCache,
    loadMoreProducts: loadMoreItems,
    filterProducts: filterItems,
    changeProductsFilters: changeItemsFilters,
    clearProductsFilters: clearItemsFilters,
    sortProducts: sortItems,
    limitProducts: limitItems,
    prefetchProduct: useCallback(
      (productNanoId: ProductNanoID) => {
        const nanoId = productNanoId as unknown as UUID;
        prefetchItem(nanoId);
      },
      [prefetchItem]
    )
  };
}

export default useProducts;
