import { useCallback } from 'react';
import compact from 'lodash/compact';

import {
  FetchProductsCacheKeys,
  UpdateProductCacheAction
} from '../../../../../productsTypes';

import { FetchProductsSetsCacheKeys } from '../../../../../../productsSets/productsSetsTypes';

import {
  TOGGLE_PRODUCT_FAVORITE_QUERY,
  ToggleProductFavoriteQueryResponse
} from '../../../../../queries/toggleProductFavorite.query';

import { useToggleProductFavorite } from '../../../../../hooks/useToggleProductFavorite';

import { useShowToastOnErrorChange } from '../../../../../../../common/hooks/useShowToastOnErrorChange';

import { ProductsListItemFavoriteButtonProduct } from './ProductsListItemFavoriteButton.types';
import { ProductsSetCache } from '../../../../../../productsSets/ProductsSetCache';
import { ProductCache } from '../../../../../ProductCache';

interface useProductsListItemFavoriteButtonProps {
  product: ProductsListItemFavoriteButtonProduct;
  productsCacheKeys?: FetchProductsCacheKeys;
  productsSetCacheKeys?: FetchProductsSetsCacheKeys;
  updateProductCache: UpdateProductCacheAction<ProductsListItemFavoriteButtonProduct>;
}

function useProductsListItemFavoriteButton({
  product,
  productsCacheKeys,
  productsSetCacheKeys,
  updateProductCache
}: useProductsListItemFavoriteButtonProps) {
  const { toggleProductFavorite, toggleProductFavoriteErrorMessage } =
    useToggleProductFavorite<ToggleProductFavoriteQueryResponse>({
      query: TOGGLE_PRODUCT_FAVORITE_QUERY,
      cacheKeys: compact([
        ProductCache.indexCacheKey(),
        ProductsSetCache.showCacheKey(),
        ...(productsCacheKeys || []),
        ...(productsSetCacheKeys || [])
      ]),
      updateProductCache
    });

  const handleToggleFavorite = useCallback<() => void>(
    () => toggleProductFavorite({ uuid: product.uuid }),
    [product, toggleProductFavorite]
  );

  useShowToastOnErrorChange({ error: toggleProductFavoriteErrorMessage });

  return {
    handleToggleFavorite
  };
}

export default useProductsListItemFavoriteButton;
