import { gql } from 'graphql-request';
import {
  ProductBrandName,
  ProductBrandUUID,
  ProductBrandID,
  ProductCreatedAt,
  ProductBlocked,
  ProductFavorite,
  ProductID,
  ProductName,
  ProductNda,
  ProductUUID,
  ProductImageUUID,
  ProductImageFile,
  ProductBrandLocalizedName,
  ProductNanoID,
  ProductRenderTypeID,
  ProductRenderTypeName
} from '../productsTypes';

export interface FetchProductsQueryResponse {
  blocked: ProductBlocked;
  favorite: ProductFavorite;
  id: ProductID;
  uuid: ProductUUID;
  nanoId: ProductNanoID;
  name: ProductName;
  nda: ProductNda;
  sku: ProductUUID;
  createdAt: ProductCreatedAt;
  updatedAt: ProductCreatedAt;
  renderTypes: {
    id: ProductRenderTypeID;
    name: ProductRenderTypeName;
  }[];
  image: {
    uuid: ProductImageUUID;
    file: ProductImageFile;
  };
  brand: {
    id: ProductBrandID;
    uuid: ProductBrandUUID;
    name: ProductBrandName;
    localizedName: ProductBrandLocalizedName;
  };
}

export const FETCH_PRODUCTS_QUERY = gql`
  query Products(
    $filters: ProductsFilters
    $sort: [ProductsSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    products(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      nodes {
        blocked
        favorite
        id
        uuid
        nanoId
        sku
        name
        nda
        createdAt
        updatedAt
        renderTypes {
          id
          name
        }
        image {
          uuid
          file
        }
        brand {
          id
          uuid
          name
          localizedName
        }
      }
      paginationInfo {
        currentPage
        nextPage
        totalCount
      }
    }
  }
`;
