import React, { useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';

import { ProductUUID } from '../../../productsTypes';
import { ClassName, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';

import { useDownloadProduct } from '../../../hooks/useDownloadProduct';
import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';
import { useDownloadNanoId } from '../../../../downloads/hooks/useDownloadNanoId';
import { useDownloadManagerModal } from '../../../../common/components/footers/DefaultFooter/components/UserDownloadManagerModalButton/hooks/useDownloadManagerModal';

import { DownloadManagerModalItems } from '../../../../common/components/footers/DefaultFooter/components/UserDownloadManagerModalButton/components/DownloadManagerModalItems';

import { SimpleModalButton } from '../../../../../helpers/buttons/SimpleModalButton';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { downloadsKeys, words } from '../../../../../locales/keys';

interface DownloadProductModalButtonProps {
  productUuid: ProductUUID;
  className?: ClassName;
  iconClassName?: ClassName;
  icon?: IconsEnum;
  i18nText?: I18nText;
  tooltipI18nText?: I18nText;
  tooltipPlacement?: TooltipPlacement;
  tooltipSingleton?: boolean;
}

function DownloadProductModalButton({
  productUuid,
  className,
  icon,
  iconClassName,
  i18nText,
  tooltipI18nText,
  tooltipPlacement,
  tooltipSingleton
}: DownloadProductModalButtonProps) {
  const {
    downloadManagerBlockOpened,
    downloads,
    downloadsFetched,
    downloadsError,
    handleStopAll,
    handleClear,
    deleteDownloadsLoading,
    deleteDownloadsErrorMessage
  } = useDownloadManagerModal();

  const {
    downloadProductLoading,
    downloadProductErrorMessage,
    downloadProduct
  } = useDownloadProduct();

  useShowToastOnErrorChange({
    error: downloadProductErrorMessage || deleteDownloadsErrorMessage
  });

  const { downloadNanoId } = useDownloadNanoId();

  const handleDownload = useCallback(() => {
    downloadProduct({
      uuid: productUuid,
      deviceNanoId: downloadNanoId
    });
  }, [productUuid, downloadNanoId, downloadProduct]);

  return (
    <SimpleModalButton
      className={className}
      i18nText={i18nText}
      iconClassName={iconClassName}
      icon={icon}
      tooltipI18nText={tooltipI18nText}
      tooltipPlacement={tooltipPlacement}
      disabled={downloadProductLoading}
      tooltipSingleton={tooltipSingleton}
      onOpen={handleDownload}
      i18nTitle={downloadsKeys.plural}
      isModalOpen={downloadManagerBlockOpened}
      submitClass="font-medium rounded-md px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
      submitButtonColor="gray"
      i18nSubmitText={words.stopAll}
      onSubmit={handleStopAll}
      onCancel={handleClear}
      i18nCancelText={words.clear}
      closeOnCancel={false}
      cancelButtonPosition="start"
      childrenClassName="flex-1 overflow-y-auto px-2 z-0"
      withoutButtons={isEmpty(downloads)}
      isLoading={deleteDownloadsLoading}
    >
      <DownloadManagerModalItems
        downloads={downloads}
        downloadsFetched={downloadsFetched}
        downloadsError={downloadsError}
      />
    </SimpleModalButton>
  );
}

export default DownloadProductModalButton;
