import React, { memo } from 'react';

import { IconsEnum } from '../../../../assets/icons/types';
import {
  FetchProductsClearProductsFilters,
  FetchProductsFilterFields,
  FetchProductsFilters,
  FetchProductsFiltersToPersist,
  FetchProductsFilterProducts,
  ProductsOnResetFiltersAction
} from '../../productsTypes';

import { BooleanFilterBadge } from '../../../../helpers/filterBadges/BooleanFilterBadge';
import { PureIconButtonHelper } from '../../../../helpers/buttons/PureIconButtonHelper';
import { ProductsBrandsFilterBadge } from '../filterBadges/ProductsBrandsFilterBadge';
import { ProductsColorsFilterBadge } from '../filterBadges/ProductsColorsFilterBadge';
import { ProductsStylesFilterBadge } from '../filterBadges/ProductsStylesFilterBadge';
import { ProductsMaterialsFilterBadge } from '../filterBadges/ProductsMaterialsFilterBadge';

import { isEveryPropEmpty } from '../../../../utils/isEveryPropEmpty';

import { words } from '../../../../locales/keys';

interface ProductsAppliedFiltersProps {
  clearProductsFilters: FetchProductsClearProductsFilters;
  onResetFilters?: ProductsOnResetFiltersAction;
  productsFilters: FetchProductsFilters;
  filterProducts: FetchProductsFilterProducts;
  filtersToPersist?: FetchProductsFiltersToPersist;
}

function ProductsAppliedFilters({
  clearProductsFilters,
  productsFilters,
  filterProducts,
  filtersToPersist = []
}: ProductsAppliedFiltersProps) {
  const persistantFilters: FetchProductsFiltersToPersist = [
    FetchProductsFilterFields.PRODUCT_CATEGORY_ID,
    FetchProductsFilterFields.PRODUCT_PARENT_CATEGORY_IDS,
    ...filtersToPersist
  ];

  const withFilters = !isEveryPropEmpty(productsFilters, persistantFilters);

  return (
    <div className="px-4">
      <div className="flex flex-wrap -m-0.5">
        <ProductsBrandsFilterBadge
          filters={productsFilters}
          name={FetchProductsFilterFields.BRAND_ID}
          onRemoveFilter={filterProducts}
        />
        <ProductsStylesFilterBadge
          filters={productsFilters}
          name={FetchProductsFilterFields.STYLE_ID}
          onRemoveFilter={filterProducts}
        />
        <ProductsMaterialsFilterBadge
          filters={productsFilters}
          name={FetchProductsFilterFields.MATERIAL_IDS}
          onRemoveFilter={filterProducts}
        />
        <ProductsColorsFilterBadge
          filters={productsFilters}
          name={FetchProductsFilterFields.COLOR_IDS}
          onRemoveFilter={filterProducts}
        />
        <BooleanFilterBadge
          filters={productsFilters}
          i18nLabel={words.blocked}
          name={FetchProductsFilterFields.BLOCKED}
          onRemoveFilter={filterProducts}
        />
        <BooleanFilterBadge
          filters={productsFilters}
          i18nLabel={words.nda}
          name={FetchProductsFilterFields.NDA}
          onRemoveFilter={filterProducts}
        />
        {withFilters && (
          <PureIconButtonHelper
            className="py-1.5 pl-1.5 pr-3 space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 m-0.5"
            i18nText={words.clearFilters}
            icon={IconsEnum.X}
            iconClassName="w-4 h-4"
            onClick={clearProductsFilters}
          />
        )}
      </div>
    </div>
  );
}

export default memo<ProductsAppliedFiltersProps>(ProductsAppliedFilters);
